import {Link} from 'react-router-dom';

import './Homepage.css'
import './App.css'
import backgroundimg from './images/backgroundcode.PNG';

function Homepage() {
    return (
        <div className = "homediv">
            <div id = "titlesdiv">
                <img src = {backgroundimg} alt = "background SOAP Bot code" height = "600px"></img>
                <div id = "titles">
                    <h1 className = "hometitles">George V. Herrmann</h1>
                    <h3 className = "hometitles">A Full-Stack software engineer</h3>
                </div>
            </div>
            <div id = "projects">
                <h2>My Projects:</h2>
                <div id = "homeinfogrid">
                    <div className = "infogriditem">
                        <h1>SOAP Bot</h1>
                        <p>
                            SOAP Bot is a multipurpose, object-oriented Discord bot written in Java using the Discord4J library.
                            SOAP Bot features fully fledged event and poll systems, game simulation (including Plinko and Blackjack),
                            an economy system, a complex trading card system, music playback, along with a suite of other commands.
                        </p>
                        <Link to = "/soapbot">Learn more about SOAP Bot</Link>
                    </div>
                    <div className = "infogriditem">
                        <h1>SOAP API</h1>
                        <p>
                            SOAP Bot can send and receive data using the SOAP API and currently keeps track of
                            SOAP Bot's most recent action. The SOAP API is written in Java using the Spring Framework.
                        </p>
                        <a href = "http://github.com/GeorgeHerrmann/soapapi" target="_blank" rel="noopener noreferrer">Learn more about the SOAP API on GitHub</a>
                    </div>
                    <div className = "infogriditem">
                        <h1>CineCity</h1>
                        <p>
                            CineCity is a collaborative mock movie ticket booking site done by a team consisting of myself and four other individuals.
                            CineCity was written in Java using the Spring Framework and React for the front end. CineCity was one of the top
                            projects for a Software Engineering class.
                        </p>
                        <a href = "http://github.com/GeorgeHerrmann" target="_blank" rel="noopener noreferrer">Learn more about CineCity on GitHub</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Homepage;